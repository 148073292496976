import { Link, useNavigate } from "@remix-run/react";
import { ChevronLeft, X } from "lucide-react";
import { cn } from "~/lib/utils";
import { Sidebar } from "./sidebar";

export function Navbar({
  title,
  backTo,
  backToClose = false,
  rightItems = [],
}: {
  title: string;
  backTo?: string | boolean;
  backToClose?: boolean;
  rightItems?: React.ReactNode[];
}) {
  const navigate = useNavigate();

  return (
    <div className="flex h-12 items-center px-1">
      {backTo === true && (
        <button className="rounded" onClick={() => navigate(-1)}>
          <BackButton isClose={backToClose} />
        </button>
      )}

      {typeof backTo === "string" && (
        <Link className="rounded" to={backTo}>
          <BackButton isClose={backToClose} />
        </Link>
      )}

      {!backTo && backTo !== false && <Sidebar />}

      <div
        className={cn("flex flex-1 items-center", {
          "justify-center": backTo === false,
        })}
      >
        <span className="line-clamp-1 font-bold">{title}</span>
      </div>

      {backTo !== false && (
        <ul className="flex h-full gap-1">
          {rightItems.map((elem, index) => (
            <li key={index} className="grid place-items-center">
              {elem}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

function BackButton({ isClose }: { isClose: boolean }) {
  if (isClose) {
    return (
      <div className="flex h-10 w-14 items-center justify-center">
        <X className="size-7 text-primary" />
      </div>
    );
  }

  return (
    <div className="flex h-10 w-14 items-center justify-center">
      <ChevronLeft className="size-7 text-primary" />
    </div>
  );
}
