import { Link, useLocation } from "@remix-run/react";
import { useOrganizationId } from "~/hooks/organization";
import { cn } from "~/lib/utils";

const tabs = [
  {
    name: "new",
    label: "新着情報",
    to: "",
    width: 45,
    height: 36,
  },
  {
    name: "event",
    label: "交流イベント",
    to: "/event",
    width: 33,
    height: 32,
  },
  {
    name: "sharing",
    label: "シェアリング",
    to: "/sharing",
    width: 32,
    height: 32,
  },
  {
    name: "group",
    label: "グループ",
    to: "/group",
    width: 33,
    height: 32,
  },
  {
    name: "partner",
    label: "地域パートナー",
    to: "/partner",
    width: 32,
    height: 23,
  },
];

export function HomeTabbar() {
  const { pathname } = useLocation();
  const { organizationId } = useOrganizationId();

  return (
    <div className="grid h-16 grid-cols-5 border-b-4 border-primary">
      {tabs.map(({ name, label, to, width, height }) => {
        const base = organizationId
          ? `/community/${organizationId}`
          : "/community";
        const link = `${base}${to}`;
        const isActive = pathname === link;

        return (
          <Link
            key={name}
            className={cn(
              "flex h-full flex-col items-center justify-center rounded-t-lg transition-colors",
              isActive ? "!bg-primary" : "bg-white",
            )}
            to={link}
            replace
          >
            <div className="mt-1 flex h-7 items-center justify-center">
              <img
                src={`/common/icon-${name}${isActive ? "-white" : ""}.svg`}
                width={width}
                height={height}
                alt=""
              />
            </div>
            <span
              className={cn(
                "mt-1 line-clamp-1 text-[10px] font-bold",
                isActive ? "text-white" : "text-gray-4",
              )}
            >
              {label}
            </span>
          </Link>
        );
      })}
    </div>
  );
}
