import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import { Link } from "@remix-run/react";
import { Check, ChevronDown } from "lucide-react";
import { Button } from "~/components/ui/button";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "~/components/ui/drawer";
import { imgWithParams } from "~/lib/utils";
import { useOrganization } from "~/routes/community.$id";

export function CommunitySwitcher() {
  const { organization, joinedOrgs } = useOrganization();

  return organization ? (
    <Drawer>
      <DrawerTrigger className="rounded px-1">
        <div className="flex h-10 items-center justify-center">
          <div className="size-8 overflow-hidden rounded bg-gray-2">
            <img
              src={
                organization.images.length > 1
                  ? imgWithParams(organization.images[1], { w: 64, h: 64 })
                  : "/common/fallback-default.png"
              }
              width={32}
              height={32}
              alt=""
            />
          </div>
          <ChevronDown className="ml-1 size-4 text-primary" strokeWidth={3} />
        </div>
      </DrawerTrigger>

      <DrawerContent>
        <DrawerHeader>
          <DrawerTitle>コミュニティの切り替え</DrawerTitle>
          <VisuallyHidden>
            <DrawerDescription>コミュニティの切り替え</DrawerDescription>
          </VisuallyHidden>
        </DrawerHeader>
        <DrawerFooter>
          <div className="space-y-4">
            <DrawerClose className="block w-full text-left">
              <div className="flex items-center text-primary">
                <img
                  className="rounded"
                  src={
                    organization.images.length > 1
                      ? imgWithParams(organization.images[1], { w: 64, h: 64 })
                      : "/common/fallback-default.png"
                  }
                  width="64"
                  height="64"
                  alt=""
                />
                <p className="ml-4 flex-1 font-bold">{organization.name}</p>
                <Check />
              </div>
            </DrawerClose>

            {joinedOrgs.length > 0 &&
              joinedOrgs.map((org) => (
                <DrawerClose key={org.id} asChild>
                  <Link className="block" to={`/community/${org.id}`} replace>
                    <div className="flex items-center">
                      <img
                        className="rounded"
                        src={
                          org.images.length > 1
                            ? imgWithParams(org.images[1], { w: 64, h: 64 })
                            : "/common/fallback-default.png"
                        }
                        width="64"
                        height="64"
                        alt=""
                      />
                      <p className="ml-4 flex-1">{org.name}</p>
                    </div>
                  </Link>
                </DrawerClose>
              ))}
          </div>

          <div className="mt-4">
            <Link to="/community/all">
              <Button className="w-full" variant="outline">
                別のコミュニティに参加する
              </Button>
            </Link>
          </div>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  ) : null;
}
