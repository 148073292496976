import * as AvatarPrimitive from "@radix-ui/react-avatar";
import * as React from "react";

import { cn, imgWithParams } from "app/lib/utils";
import ConditionalWrapper from "../parts/conditionalWrapper";

const Avatar = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root> & {
    concierge?: boolean;
  }
>(({ className, concierge = false, ...props }, ref) => (
  <ConditionalWrapper
    condition={concierge}
    wrapper={(children) => (
      <div>
        <div className="relative inline-block">{children}</div>
      </div>
    )}
  >
    <AvatarPrimitive.Root
      ref={ref}
      className={cn(
        "relative flex size-12 shrink-0 overflow-hidden rounded-full border",
        className,
      )}
      {...props}
    />

    {concierge && (
      <img
        className="absolute -bottom-0.5 left-1/2 -translate-x-1/2"
        src="/common/icon-share-concierge-label.svg"
        width="36"
        height="12"
        alt=""
      />
    )}
  </ConditionalWrapper>
));
Avatar.displayName = AvatarPrimitive.Root.displayName;

const AvatarImage = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Image>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image> & {
    size?: number;
  }
>(({ className, src, size, ...props }, ref) => {
  return (
    <AvatarPrimitive.Image
      ref={ref}
      className={cn("aspect-square h-full w-full object-cover", className)}
      {...props}
      src={getOptimizedAvatarSrc(src, size)}
    />
  );
});
AvatarImage.displayName = AvatarPrimitive.Image.displayName;

const AvatarFallback = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Fallback>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Fallback
    ref={ref}
    className={cn(
      "flex h-full w-full items-center justify-center rounded-full bg-muted text-neutral-600",
      className,
    )}
    {...props}
  />
));
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName;

export function AvatarConciergeBadge() {
  return (
    <div className="absolute bottom-0 right-0">
      <img
        src="/common/icon-concierge-badge.png"
        className="w-5"
        alt="コンシェルジュ"
        width="100"
        height="137"
      />
    </div>
  );
}

export function getOptimizedAvatarSrc(src?: string, size: number = 48) {
  return !src
    ? "/common/fallback-default.png"
    : src.includes("cloudimg.io")
      ? imgWithParams(src, { w: size - 2, h: size - 2, func: "crop" }) // -2 for border
      : src;
}

export { Avatar, AvatarFallback, AvatarImage };
